import React from "react"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import Section from "../components/section"

import styles from "./index.module.css"

import MugshotImg from "../assets/mugshot2.jpg"

export default () => <>
    <SEO />
    <Layout>
        <Section>
            <div className={styles.narrow}>
                <div className={styles.mugshot}>
                    <img src={MugshotImg} alt="My mugshot"/>
                </div>
                <div className={styles.frontBlurb}>
		        <p>Hi, I'm a postdoc in the <a href="https://perimeterinstitute.ca/quantum-gravity">Quantum Gravity</a> group at Perimeter Institute.</p>
		        <p>I spend most of my time thinking about the role of quantum information in quantum gravity.</p>
		        <p>I used to be a postdoc in the <a href="https://groups.oist.jp/quast">Qubits and Spacetime Unit</a> at the Okinawa Institute of Science and Technology. Before that I was a PhD student in the <a href="http://www.damtp.cam.ac.uk/research/gr/">Relativity and Gravitation Group</a> at DAMTP in the University of Cambridge.</p>
		        <p>You can reach me at <a href="mailto:jkirklin@pitp.ca">jkirklin@pitp.ca</a> or <a href="mailto:josh@kirklin.science">josh@kirklin.science</a>.</p>
	        </div>
            </div>
        </Section>
        <hr />
        <Section>
            <div style={{'font-size': '1.3rem', 'text-align':'center'}}>
                <a href="https://arxiv.org/a/kirklin_j_1">arXiv</a>, <a href="http://inspirehep.net/author/profile/J.Kirklin.1">inspire</a>, <a href="https://scholar.google.com/citations?user=AxJa2FYAAAAJ">Google Scholar</a>, <a href="https://orcid.org/0000-0001-5155-9814">ORCiD</a>
            </div>
        </Section>
    </Layout>
</>
